<template>
  <div>
    <button
      class="btn btn-outline-blue mb-3"
      @click="addEmail"
      v-if="$can({ key: 'clients', expectedPermission: 'edit' })"
    >
      <icon type="plus" /> Add Email
    </button>
    <alert class="my-4" v-if="emails.isLoading" />
    <alert
      class="my-4"
      v-if="!emails.isLoading && emails.data.length == 0"
      :hideLoader="true"
      >No Emails</alert
    >
    <div
      class="card border-0 overflow-auto mobile-tablet-no-card p-2 p-md-0"
      v-if="!emails.isLoading && emails.data.length > 0"
    >
      <table class="table table-striped mb-0 responsive-mobile-tablet-table">
        <thead>
          <tr>
            <th>Email</th>
            <th
              v-if="$can({ key: 'clients', expectedPermission: 'edit' })"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="email in emails.data" :key="email.id">
            <td>
              <b class="mobile-tablet-only mr-2">Email:</b
              ><a :href="`mailto:${email.email}`">{{ email.email }}</a>
            </td>
            <td
              class="text-right"
              v-if="$can({ key: 'clients', expectedPermission: 'edit' })"
              :class="`${
                user && !user.isPatient && emails.data.length > 1
                  ? 'td-actions'
                  : ''
              }`"
            >
              <button
                v-if="user && !user.isPatient && emails.data.length > 1"
                class="btn btn-danger btn-sm"
                @click="deleteEmail(email)"
                :disabled="email.isDeleting"
              >
                <icon type="trash" v-if="!email.isDeleting" />
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="email.isDeleting"
                ></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "PatientEmail",
  computed: {
    ...mapState({
      patients: (state) => state.patients,
      emails: (state) => state.patients.emails,
      patient: (state) => state.patients.patient,
      user: (state) => state.auth.user,
    }),
  },
  mounted() {
    this.get(this.patient.user_id);
  },
  methods: {
    ...mapActions({
      get: "patients/emails/get",
      remove: "patients/emails/remove",
    }),
    addEmail: function () {
      this.$router.push({
        name: "patients.emails.create",
        params: { id: this.patient.id },
        query: { src: "emails" },
      });
    },
    deleteEmail: function (email) {
      email.isDeleting = true;
      let vm = this;
      vm.$swal({
        title: "Are you sure",
        text: "Are you sure you want to delete this email?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          vm.remove({
            user: this.patient.user_id,
            id: email.id,
          });
        } else {
          email.isDeleting = false;
        }
      });
    },
  },
};
</script>
